<template>
    <AppLayout>
      <div class="max-w-7xl px-5 md:px-20 py-5 mx-auto flex flex-col space-y-5">
        <h1 class="mb-4 md:mb-8">{{ $t('StatusTitle') }}</h1>
        <div class="grid grid-cols-4 gap-8 w-full h-24 md:h-52 text-center">
          <a href="/assets/statuts.pdf" download class="download-card">
            <img src="/assets/pdf-icon.png" alt="icon pdf" class="w-8 md:w-14">
            <span class="text-sm md:text-lg">{{ $t('StatusFile1') }}</span>
          </a>
          <a href="/assets/ra2021.pdf" download class="download-card">
            <img src="/assets/pdf-icon.png" alt="icon pdf" class="w-8 md:w-14">
            <span class="text-sm md:text-lg">{{ $t('AnnualReport2021') }}</span>
          </a>
          <a href="/assets/ra2022.pdf" download class="download-card">
            <img src="/assets/pdf-icon.png" alt="icon pdf" class="w-8 md:w-14">
            <span class="text-sm md:text-lg">{{ $t('AnnualReport2022') }}</span>
          </a>
          <a href="/assets/ra2023.pdf" download class="download-card">
            <img src="/assets/pdf-icon.png" alt="icon pdf" class="w-8 md:w-14">
            <span class="text-sm md:text-lg">{{ $t('AnnualReport2023') }}</span>
          </a>
        </div>
      </div>
    </AppLayout>
  </template>
<script setup>
</script>

<style scoped>

.download-card {
  @apply bg-custom-light-blue border-2 border-custom-blue
  hover:bg-custom-blue hover:border-custom-dark-blue hover:underline
  rounded-lg p-3 flex flex-col justify-center place-items-center gap-4
  transition ease-in-out duration-200;
}

</style>