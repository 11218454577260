<template>
  <AppLayout>
    <div class="max-w-7xl md:px-20 px-5 py-5 mx-auto space-y-2 md:space-y-5">
      <h1 class="pb-4">{{ $t('AssociationTitle') }}</h1>
      <img src="/assets/lassociation-img.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image association meuh">
      <span class="flex justify-center text-center font-semibold italic md:text-2xl">"{{ $t('AssociationImageDesc') }}"</span>
      <div class="space-y-8 md:space-y-12 pt-2 md:pt-4">
        <div class="space-y-1 md:space-y-2">
          <h2>{{ $t('AssociationRole') }}</h2>
          <p>{{ $t('AssociationRoleDesc') }}</p>
        </div>
        <div class="space-y-1 md:space-y-2">
          <h2>{{ $t('AssociationBenefits') }}</h2>
          <p>{{ $t('AssociationBenefitsDesc') }}</p>
        </div>
        <div class="space-y-1 md:space-y-2">
          <h2>{{ $t('AssociationObjectives') }}</h2>
          <ul class="space-y-3 md:space-y-5 pt-1 md:pt-2">
            <li class="flex items-center space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_1') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_2') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_3') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_4') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_5') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_6') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_7') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_8') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('AssociationObjectives_9') }}</p>
            </li>
          </ul>
        </div>
        <div>
          <h2>{{ $t('AssociationTeam') }}</h2>
          <div class="grid grid-cols-2 md:grid-cols-3 gap-x-2 md:gap-x-8 gap-y-4 pt-4">
            <div class="flex flex-col text-center space-y-1 md:space-y-2">
              <img src="/assets/lassociation-xavier.png" class="border-2 border-custom-blue rounded-lg" alt="image xavier schmidt">
              <h5>{{ $t('AssociationTeamXavier') }}</h5>
              <p>{{ $t('AssociationTeamXavierDesc') }}</p>
            </div>
            <div class="flex flex-col text-center space-y-1 md:space-y-2">
              <img src="/assets/lassociation-marius.png" class="border-2 border-custom-blue rounded-lg" alt="image marius zainea">
              <h5>{{ $t('AssociationTeamMarius') }}</h5>
              <p>{{ $t('AssociationTeamMariusDesc') }}</p>
            </div>
            <div class="flex flex-col text-center space-y-1 md:space-y-2">
              <img src="/assets/lassociation-vithursan.png" class="border-2 border-custom-blue rounded-lg" alt="image vithursan sivapalan">
              <h5>{{ $t('AssociationTeamVithursan') }}</h5>
              <p>{{ $t('AssociationTeamVithursanDesc') }}</p>
            </div>
            <div class="flex flex-col text-center space-y-1 md:space-y-2">
              <img src="/assets/lassociation-ilyas.png" class="border-2 border-custom-blue rounded-lg" alt="image ilyas siouda">
              <h5>{{ $t('AssociationTeamIlyas') }}</h5>
              <p>{{ $t('AssociationTeamIlyasDesc') }}</p>
            </div>
            <div class="flex flex-col text-center space-y-1 md:space-y-2">
              <img src="/assets/lassociation-loic.png" class="border-2 border-custom-blue rounded-lg" alt="image loïc emmert">
              <h5>{{ $t('AssociationTeamLoic') }}</h5>
              <p>{{ $t('AssociationTeamLoicDesc') }}</p>
            </div>

          </div>
        </div>
        <div class="space-y-1 md:space-y-2">
          <h2>{{ $t('AssociationHistory') }}</h2>
          <p>{{ $t('AssociationHistoryDesc') }}</p>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
<script setup>
</script>