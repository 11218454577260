import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import { createI18n } from 'vue-i18n'
import messages from './data/messages.js'
import AppLayout from '@/components/AppLayout.vue'
import './assets/style.css'

export const i18n = createI18n({
    locale: localStorage.getItem('selectedLanguage') || 'fr',
    messages,
    fallbackLocale: 'fr'
})

const app = createApp(App)

app.component('AppLayout', AppLayout)

app.use(router).use(i18n).mount('#app')
