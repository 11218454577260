import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AssociationView from '../views/AssociationView.vue'
import ProjectView from '../views/ProjectView.vue'
import SupportView from '../views/SupportView.vue'
import WorkshopView from '../views/WorkshopView.vue'
import GalleryView from '../views/GalleryView.vue'
import StatusView from '../views/StatusView.vue'
import ContactView from '../views/ContactView.vue'
import { i18n } from '../main.js';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'titles.home'
    }
  },
  {
    path: '/association',
    name: 'Association',
    component: AssociationView,
    meta: {
      title: 'titles.association'
    }
  },  
  {
    path: '/projets',
    name: 'Projets',
    component: ProjectView,
    meta: {
      title: 'titles.projects'
    }
  },  
  {
    path: '/donations',
    name: 'Donations',
    component: SupportView,
    meta: {
      title: 'titles.donation'
    }
  },
  {
    path: '/galerie',
    name: 'Galerie',
    component: GalleryView,
    meta: {
      title: 'titles.gallery'
    }
  },
  {
    path: '/atelier',
    name: 'Atelier',
    component: WorkshopView,
    meta: {
      title: 'titles.workshop'
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: SupportView,
    meta: {
      title: 'titles.support'
    }
  },
  {
    path: '/status',
    name: 'Status',
    component: StatusView,
    meta: {
      title: 'titles.status'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView,
    meta: {
      title: 'titles.contact'
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const menu = document.getElementById('menu');
  if (menu && menu.classList.contains('active')) {
    menu.classList.remove('active');
    document.body.style.overflow = '';
  }
  document.title = i18n.global.t(to.meta.title);
  next();
});

export default router
