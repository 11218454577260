<template>
  <AppLayout>
    <div class="max-w-7xl px-5 md:px-20 py-5 mx-auto">
      <h1 class="pb-4">{{ $t('ContactTitle') }}</h1>
      <p class="flex flex-col items-center text-center">
        <span>{{ $t('ContactDesc_1') }}</span>
        <span>{{ $t('ContactDesc_2') }}</span>
      </p>
      <form method="POST" action="https://formsubmit.co/atelier.meuh@gmail.com" class="w-full flex flex-col space-y-5 mt-8 md:mt-16">
          <div class="flex space-y-5 md:space-x-5 md:space-y-0 flex-col md:flex-row">
            <div class="w-full flex flex-col">
              <label for="Name" class="font-semibold md:text-2xl">{{ $t('input.LabelName') }}</label>
              <input type="text" name="Prénom" class="h-12 w-full border-custom-blue border-2 rounded-lg px-3 input-bg-color focus:outline-none focus:border-custom-dark-blue mt-0.5 md:mt-1" :placeholder="$t('input.Name')"  oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" oninput="setCustomValidity('')" required>
            </div>
            <div class="w-full flex flex-col">
              <label for="Name" class="font-semibold md:text-2xl">{{ $t('input.LabelSurname') }}</label>
              <input type="text" name="Nom" class="h-12 w-full border-custom-blue border-2 rounded-lg px-3 input-bg-color focus:outline-none focus:border-custom-dark-blue mt-0.5 md:mt-1" :placeholder="$t('input.Surname')" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" oninput="setCustomValidity('')" required>
            </div>
          </div>
          <div class="flex space-y-5 md:space-x-5 md:space-y-0 flex-col md:flex-row">
            <div class="w-full flex flex-col">
              <label for="Name" class="font-semibold md:text-2xl">{{ $t('input.LabelEmail') }}</label>
              <input type="text" name="Email" class="h-12 w-full border-custom-blue border-2 rounded-lg px-3 input-bg-color focus:outline-none focus:border-custom-dark-blue mt-0.5 md:mt-1" :placeholder="$t('input.Email')" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" oninput="setCustomValidity('')" required>
            </div>
            <div class="w-full flex flex-col">
              <label for="Name" class="font-semibold md:text-2xl">{{ $t('input.LabelTel') }}</label>
              <input type="tel" name="Téléphone" class="h-12 w-full border-custom-blue border-2 rounded-lg px-3 input-bg-color focus:outline-none focus:border-custom-dark-blue mt-0.5 md:mt-1" :placeholder="$t('input.Tel')" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" oninput="setCustomValidity('')" required>
            </div>
          </div>
          <div class="flex flex-col">
              <label for="Message" class="font-semibold md:text-2xl">{{ $t('input.LabelMessage') }}</label>
              <textarea name="message" class="h-24 md:h-40 w-full border-custom-blue border-2 rounded-lg p-3 input-bg-color focus:outline-none focus:border-custom-dark-blue mt-0.5 md:mt-1" :placeholder="$t('input.Message')" oninvalid="this.setCustomValidity('Veuillez remplir ce champ')" oninput="setCustomValidity('')" required></textarea>
          </div>
          <div class="w-full px-5 md:px-20 bg-custom-dark-blue rounded-lg text-soft-white py-2 hover:bg-custom-blue">
              <input type="submit" value="Envoyer" class="py-2 w-full text-center font-semibold cursor-pointer">
              <input type="hidden" name="_next" value="https://association-meuh.ch/">
          </div>
      </form>
    </div>
  </AppLayout>
</template>

<style>
  .input-bg-color {
      background-color: #CAF0F8;
  }
</style>