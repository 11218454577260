<template>
  <div>
    <header class="max-w-7xl pr-5 md:px-20 py-5 mx-auto">
      <div class="flex justify-between items-center lg:hidden">
        <svg
          class="ham hamRotate ham4"
          viewBox="0 0 100 100"
          width="70"
          onclick="this.classList.toggle('active')"
          @click="toggleMenu"
        >
          <path
            class="line top"
            d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
          />
          <path class="line middle" d="m 70,50 h -40" />
          <path
            class="line bottom"
            d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
          />
        </svg>
        <router-link to="/"
          ><img src="/assets/logo.png" alt="logo"
        /></router-link>
        <div class="language-selector">
          <div v-if="$i18n.locale === 'fr'" class="flex items-center">
            <img src="/assets/fr-flag.svg" alt="French flag" class="mr-2" />
            <!-- added margin-right for some space -->
            <select
              v-model="$i18n.locale"
              @change="updateLanguage($event.target.value)"
            >
              <option value="fr">FR</option>
              <option value="en">ENG</option>
            </select>
          </div>

          <div v-else class="flex items-center">
            <img src="/assets/en-flag.svg" alt="English flag" class="mr-2" />
            <!-- added margin-right for some space -->
            <select
              v-model="$i18n.locale"
              @change="updateLanguage($event.target.value)"
            >
              <option value="fr">FR</option>
              <option value="en">ENG</option>
            </select>
          </div>
        </div>
      </div>
      <nav class="hidden lg:flex justify-between items-center">
        <router-link to="/"
          ><img src="/assets/logo.png" alt="logo"
        /></router-link>
        <router-link
          to="/association"
          active-class="active-link"
          class="font-semibold hover:text-custom-dark-blue"
          >{{ $t("NavbarAssociation") }}</router-link
        >
        <router-link
          to="/atelier"
          active-class="active-link"
          class="font-semibold hover:text-custom-dark-blue"
          >{{ $t("NavbarWorkshop") }}</router-link
        >
        <router-link
          to="/projets"
          active-class="active-link"
          class="font-semibold hover:text-custom-dark-blue"
          >{{ $t("NavbarProjects") }}</router-link
        >
        <router-link
          to="/galerie"
          active-class="active-link"
          class="font-semibold hover:text-custom-dark-blue"
          >{{ $t("NavbarGallery") }}</router-link
        >
        <router-link
          to="/contact"
          active-class="active-link"
          class="font-semibold hover:text-custom-dark-blue"
          >{{ $t("NavbarContact") }}</router-link
        >
        <div class="language-selector">
          <div v-if="$i18n.locale === 'fr'" class="flex items-center">
            <img src="/assets/fr-flag.svg" alt="French flag" class="mr-2" />
            <!-- added margin-right for some space -->
            <select
              v-model="$i18n.locale"
              @change="updateLanguage($event.target.value)"
            >
              <option value="fr">FR</option>
              <option value="en">ENG</option>
            </select>
          </div>

          <div v-else class="flex items-center">
            <img src="/assets/en-flag.svg" alt="English flag" class="mr-2" />
            <!-- added margin-right for some space -->
            <select
              v-model="$i18n.locale"
              @change="updateLanguage($event.target.value)"
            >
              <option value="fr">FR</option>
              <option value="en">ENG</option>
            </select>
          </div>
        </div>
        <router-link
          to="/support"
          active-class="active-link-support"
          class="font-semibold p-3 bg-custom-dark-blue hover:bg-custom-blue text-soft-white rounded-lg transition ease-in-out 1s"
          >{{ $t("NavbarSupport") }}</router-link
        >
      </nav>
    </header>

    <nav class="sliding-menu pl-6" id="menu">
      <ul class="space-y-5 mt-12 md:pl-20">
        <li>
          <router-link
            to="/association"
            active-class="active-link"
            class="font-semibold text-2xl"
            >{{ $t("NavbarAssociation") }}</router-link
          >
        </li>
        <hr class="w-8 border-t-2 border-soft-white-20" />
        <li>
          <router-link
            to="/atelier"
            active-class="active-link"
            class="font-semibold text-2xl"
            >{{ $t("NavbarWorkshop") }}</router-link
          >
        </li>
        <hr class="w-8 border-t-2 border-soft-white-20" />
        <li>
          <router-link
            to="/projets"
            active-class="active-link"
            class="font-semibold text-2xl"
            >{{ $t("NavbarProjects") }}</router-link
          >
        </li>
        <hr class="w-8 border-t-2 border-soft-white-20" />
        <li>
          <router-link
            to="/galerie"
            active-class="active-link"
            class="font-semibold text-2xl"
            >{{ $t("NavbarGallery") }}</router-link
          >
        </li>
        <hr class="w-8 border-t-2 border-soft-white-20" />
        <li>
          <router-link
            to="/contact"
            active-class="active-link"
            class="font-semibold text-2xl"
            >{{ $t("NavbarContact") }}</router-link
          >
        </li>
        <hr class="w-8 border-t-2 border-soft-white-20" />
        <li>
          <router-link
            to="/support"
            active-class="active-link"
            class="font-semibold text-2xl"
            >{{ $t("NavbarSupport") }}</router-link
          >
        </li>
      </ul>
    </nav>

    <slot></slot>

    <footer class="mt-8 md:mt-16">
      <hr class="w-full border-t-2 border-custom-dark-blue border-opacity-20" />
      <div class="max-w-7xl px-5 md:px-20 py-5 mx-auto">
        <img
          src="/assets/logo.png"
          class="mx-auto"
          alt="logo association meuh"
        />
        <div class="md:flex md:flex-col md:items-center">
          <h2 class="mt-12">{{ $t("FooterTitle") }}</h2>
          <nav
            class="flex flex-col md:flex-row md:justify-between gap-4 mt-2 md:mt-6 md:text-lg w-full md:w-auto mb-6"
          >
            <router-link
              to="/association"
              @click="scrollToTop"
              active-class="active-link"
              class="hover:text-custom-dark-blue"
              >{{ $t("FooterAssociation") }}</router-link
            >
            <router-link
              to="/atelier"
              @click="scrollToTop"
              active-class="active-link"
              class="hover:text-custom-dark-blue"
              >{{ $t("FooterWorkshop") }}</router-link
            >
            <router-link
              to="/projets"
              @click="scrollToTop"
              active-class="active-link"
              class="hover:text-custom-dark-blue"
              >{{ $t("FooterProjects") }}</router-link
            >
            <router-link
              to="/galerie"
              @click="scrollToTop"
              active-class="active-link"
              class="hover:text-custom-dark-blue"
              >{{ $t("FooterGallery") }}</router-link
            >
            <router-link
              to="/contact"
              @click="scrollToTop"
              active-class="active-link"
              class="hover:text-custom-dark-blue"
              >{{ $t("FooterContact") }}</router-link
            >
            <router-link
              to="/support"
              @click="scrollToTop"
              active-class="active-link"
              class="hover:text-custom-dark-blue"
              >{{ $t("FooterSupport") }}</router-link
            >
            <router-link
              to="/status"
              @click="scrollToTop"
              active-class="active-link"
              class="hover:text-custom-dark-blue"
              >{{ $t("FooterStatus") }}</router-link
            >
          </nav>
          <a
            href="https://www.instagram.com/associationmeuh/"
            class="hover:opacity-60"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path
                d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160ZM176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24Zm40,152a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V80A40,40,0,0,1,80,40h96a40,40,0,0,1,40,40ZM192,76a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z"
              ></path></svg
          ></a>
        </div>
        <div
          class="flex flex-col items-center justify-center mt-12 space-y-1 text-center text-sm md:text-base"
        >
          <span>{{ $t("FooterRights") }}</span>
          <div>
            <span>{{ $t("FooterAmasix") }} </span>
            <span class="font-semibold">
              <a
                href="https://www.linkedin.com/in/lucafresard?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAADOT5EYBuNtLAeVV89FRcYOaSexqqGjEzBs&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BketrO6DVRaufLYQdBPCFpg%3D%3D"
                class="hover:underline transition ease-in-out duration-200"
              >
                Luca</a
              >
              &
              <a
                href="https://noe-schmidt.ch/"
                class="hover:underline transition ease-in-out duration-200"
                >Noé</a
              >
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "AppLayout",
  methods: {
    toggleMenu() {
      const menu = document.getElementById("menu");
      menu.classList.toggle("active");

      if (menu.classList.contains("active")) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
    updateLanguage(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("selectedLanguage", locale);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.active-link {
  color: #0077b6;
}

.active-link-support {
  background-color: #caf0f8;
  color: #0077b6;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: black;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham4 .top {
  stroke-dasharray: 40 121;
}
.ham4 .bottom {
  stroke-dasharray: 40 121;
}
.ham4.active .top {
  stroke-dashoffset: -68px;
}
.ham4.active .bottom {
  stroke-dashoffset: -68px;
}

.sliding-menu {
  position: fixed;
  top: 110px;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #fdfaf5;
  transition: left 0.3s;
  z-index: 1000;
}

.sliding-menu.active {
  left: 0;
}

select {
  background-color: #fdfaf5;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

select:focus {
  outline: none;
}
</style>
