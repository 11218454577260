export default {
  en: {
    titles: {
      home: "Home",
      association: "Association",
      gallery: "Gallery",
      support: "Support us",
      workshop: "Workshop",
      projects: "Projects",
      contact: "Contact",
      donation: "Donation",
      status: "Status",
    },
    input: {
      LabelName: "Name",
      LabelSurname: "Surname",
      LabelEmail: "Email",
      LabelMessage: "Message",
      LabelTel: "Phone number",
      SubmitCTA: "Submit",
      Name: "Enter your name...",
      Surname: "Enter your surname...",
      Email: "Enter your email...",
      Message: "Enter your message...",
      Tel: "Enter your phone number...",
    },
    NavbarAssociation: "Association",
    NavbarWorkshop: "Workshop",
    NavbarProjects: "Projects",
    NavbarGallery: "Gallery",
    NavbarContact: "Contact us",
    NavbarSupport: "Support us",
    FooterTitle: "On the site",
    FooterAssociation: "Association",
    FooterWorkshop: "Workshop",
    FooterProjects: "Projects",
    FooterGallery: "Gallery",
    FooterContact: "Contact us",
    FooterSupport: "Support us",
    FooterStatus: "Bylaws & Annual Reports",
    FooterRights: "© Association Meuh 2024 - All rights reserved",
    FooterAmasix: "Site created by",
    FooterAmasixLink: " the Amasix",
    HomeTitle: "Association Meuh",
    HomeDesc:
      "The Association Meuh is a non-profit organization recognized as being of public utility. It promotes the integration and reintegration of people in difficulty. Based in the region of three Chêne (Geneva) since 2008, it offers a wide range of technical and artistic activities carried out in a collective socio-educational workspace with the help of volunteers.",
    AssociationTitle: "Association",
    AssociationImageDesc:
      "Collective sharing of talents for the benefit of all",
    AssociationRole: "Our role",
    AssociationRoleDesc:
      "Our team was incorporated as a non-profit association in 2021. It was granted public utility status in 2023. Based in Chêne-Bourg (Geneva) since 2008, we promote the integration and reintegration of people in difficulty through a wide range of technical and artistic activities carried out in a collective socio-educational workspace, accompanied by volunteers and specialists.",
    AssociationBenefits: "Our beneficiaries",
    AssociationBenefitsDesc:
      "We welcome all kinds of people from different backgrounds and walks of life: school drop-outs, senior citizens looking for work and socialization, adults reintegrating into society or on disability insurance, migrants seeking to integrate by practicing French, job-seekers or those at the end of their entitlement, new non-French-speaking residents of Geneva, etc...",
    AssociationObjectives: "Our objectives",
    AssociationObjectives_1:
      "To be a recognized player in Geneva's social cohesion",
    AssociationObjectives_2:
      "Stimulate learning and know-how through the production of complex technical objects",
    AssociationObjectives_3:
      "Encourage responsibility, rigor, self-confidence and individual autonomy",
    AssociationObjectives_4:
      "Share the values of citizenship, listening, solidarity and respect",
    AssociationObjectives_5: "Providing creative, supervised stimulation",
    AssociationObjectives_6:
      "Providing a multicultural, multi-activity exchange space",
    AssociationObjectives_7:
      "Highlighting the artistic talents of each individual to help our beneficiaries regain a foothold in working life after a difficult period",
    AssociationObjectives_8:
      "Helping people get back into working life and make the most of their knowledge and skills",
    AssociationObjectives_9:
      "Supporting the creation of individual micro-enterprises through advice and monitoring",
    AssociationTeam: "Our team",
    AssociationTeamXavier: "Xavier Schmidt",
    AssociationTeamXavierDesc: "Workshop manager",
    AssociationTeamMarius: "Marius Zainea",
    AssociationTeamMariusDesc: "Accountant",
    AssociationTeamVithursan: "Vithursan Sivapalan",
    AssociationTeamVithursanDesc: "Laser cutting machine operator",
    AssociationTeamIlyas: "Ilyas Siouda",
    AssociationTeamIlyasDesc: "CAD/CAM engineer",
    AssociationTeamLoic: "Loïc Emmert",
    AssociationTeamLoicDesc: "Aeronautical engineer",
    AssociationHistory: "Our history",
    AssociationHistoryDesc:
      "Our collaborative studio was created in 2008. At the time, it housed several independent artists and craftspeople. After several name changes, the workshop became Atelier Meuh, a self-managed non-profit space available to artists and entrepreneurs under certain conditions. Then, we are constituted in association Chêne-Bourg, 25 June 2021.",
    WorkshopTitle: "Workshop",
    WorkshopDesc:
      "A space where everyone can create and learn about model aircraft, composites and plastic arts",
    WorkshopExpertise: "Our expertise",
    WorkshopCutting: "Cutting",
    WorkshopCuttingDesc:
      "Creation and cutting of parts on dedicated machines...saving precious time in the manufacturing process! An activity requiring preparation, concentration, patience and creativity.",
    WorkshopMolding: "Molding",
    WorkshopMoldingDesc:
      "Moulding work in composite materials, resins and various materials: carbon, Kevlar, fiberglass, etc... An activity that can be included in customized courses.",
    WorkshopClassicWork: "Classic work",
    WorkshopClassicWorkDesc:
      "Classic or specialized know-how with a variety of tools (wood lathe, sanders, etc.) and materials (light woods, foam, concrete, plaster, paints, recycled materials, balsa, plywood, fiberglass, etc.). Custom-built objects and restoration of old furniture.",
    WorkshopEquipment: "Our equipment",
    WorkshopEquipmentDesc:
      "The workshop is mainly equipped with traditional tools. Our machines are artistic hobby models.",
    WorkshopEquipment_1:
      "Antique architect's drawing table with rulers and squares",
    WorkshopEquipment_2: "Hot wire cutter for polystyrene",
    WorkshopEquipment_3: "CNC laser cutter",
    WorkshopEquipment_4: "CNC milling machine",
    WorkshopCourse: "Our courses",
    WorkshopCourseTargetAudience: "Target audience",
    WorkshopCourseTargetAudience_1:
      "Young art and design school students wishing to learn or test particular techniques",
    WorkshopCourseTargetAudience_2:
      "Young people (aged 15 and over) undergoing socio-educational or socio-professional reintegration",
    WorkshopCourseTargetAudience_3:
      "Adults looking for a job and wishing to acquire new skills",
    WorkshopCourseMain: "Main activity taught: artistic aeromodelling",
    WorkshopCourseMainDesc:
      "Using sketches, a plan or a kit, build and fly a radio-controlled flying machine. A creative, spectacular and original activity, it requires technique, care, patience and precision: a flying cow or hippopotamus, a prehistoric bird, Noah's Ark, a drone with a hornet body, a giant spider, etc. Anything is possible!",
    WorkshopPrice: "Rates and conditions : ",
    WorkshopPriceCTA: "Contact us !",
    ProjectTitle: "Projects",
    ProjectCow: "Project N°2 - The flying cow",
    ProjectCowDesc:
      "Project to design and build a real small airplane (not a scale model) powered by an electric motor, with a cow-shaped fuselage.",
    ProjectCowObjectives: "Objectives",
    ProjectCowObjectives_1:
      "Under the supervision of the Swiss Federal Office of Civil Aviation (FOCA) and in collaboration with Experimental Aviation of Switzerland (EAS), design and build a small, single-seat (or two-seat) light aircraft with a cow-shaped fuselage and a non-polluting electric motor.",
    ProjectCowObjectives_2:
      "Unite the members of the association around an original and unique project to share the values of integration and diversity by promoting Switzerland through the cow symbol.",
    ProjectCowConstruction: "Construction stages",
    ProjectCowConstructionDesc:
      "The first stage of the project is to commission an aeronautical engineer to carry out an essential technical, legislative and financial feasibility study. Estimated cost: CHF 30,000. Once this stage has been completed, we will draw up the manufacturing plans for the prototype, then organize its production in partnership with a number of specialists and organizations in the region.",
    ProjectCowDetails:
      "Details (provisional) of the imagined prototype aircraft",
    ProjectCowDetails_1: "Aircraft type:",
    ProjectCowDetails_1_1:
      " experimental, FOCA/EAS-certified in Switzerland, single-seater or two-seater",
    ProjectCowDetails_2: "Fuselage:",
    ProjectCowDetails_2_2:
      " fiberglass and carbon skin, tubular structure in welded steel, composites or wood.",
    ProjectCowDetails_3: "Shape:",
    ProjectCowDetails_3_3: " Holstein cow (white, spotted black or brown)",
    ProjectCowDetails_4: "Motorization:",
    ProjectCowDetails_4_4: " Siemens or Pipistrel kit, electric (35 to 50 hp)",
    ProjectCowDetails_5: "Wingspan:",
    ProjectCowDetails_5_5: " approx. 9 metres - Length: approx. 6.50 metres",
    ProjectCowDetails_6: "Weight:",
    ProjectCowDetails_6_6: " according to current regulations",
    ProjectCowDetails_7: "Flight speed:",
    ProjectCowDetails_7_7: " approx. 90 km/h",
    ProjectCowDetails_8: "Ancillary equipment:",
    ProjectCowDetails_8_8: " smoke and sound system (bellowing or bells)",
    ProjectCowCTA: "Take part in this project !",
    ProjectPlane: "Project N°1 - Building a light aircraft kit",
    ProjectPlaneObjectives: "Objectives",
    ProjectPlaneObjectives_1:
      "Collective construction of a small tourist airplane kit in a socio-educational environment in the Arve & Lac and 3 Chêne regions.",
    ProjectPlaneObjectives_2:
      "Supporting the integration of people in difficulty and the professional reintegration of diverse individuals",
    ProjectPlaneObjectives_3:
      "Welcome and supervise participants with the help of specialized professionals and volunteers",
    ProjectPlaneObjectives_4:
      "Introduce interested parties to flight simulators, and later to learning to fly in a safe environment.",
    ProjectPlaneObjectives_5:
      "Offer first flights on our aircraft once completed",
    ProjectPlaneBudget: "Our needs for this project",
    ProjectPlaneBudget_1:
      "A 150 m2 construction workshop with a garage door to let the plane in and out (with folding wings)",
    ProjectPlaneBudget_2: "At least 2 to 3 assistants at 80 / 100%",
    ProjectPlaneBudget_3: "Some construction equipment, furnishings and tools",
    ProjectPlaneBudget_4: "An airplane kit with engine and instruments",
    ProjectPlaneBudget_5: "Assistance with annual workshop running costs",
    ProjectPlaneCTA: "Take part in this project!",
    ContactTitle: "Contact us",
    ContactDesc_1: "Would you like to make a comment ?",
    ContactDesc_2: "Please use the form below.",
    ContactInputTitleFirstName: "First name",
    ContactInputFirstName: "Enter your first name",
    ContactInputTitleName: "Name",
    ContactInputName: "Enter your name",
    ContactInputTitleEmail: "Email",
    ContactInputEmail: "Enter your email",
    ContactInputTitlePhone: "Phone",
    ContactInputPhone: "(+41) 77 777 77 77",
    ContactInputTitleMessage: "Message",
    ContactInputMessage: "Leave a further message",
    ContactInputCTA: "Send",
    SupportTitle: "Support us",
    SupportDescription_1: "Would you like to support our social initiatives ?",
    SupportDescription_2: "Make a donation or partner with us.",
    SupportDonation: "Make a donation",
    SupportDonationDescripton:
      "As we are not subsidized, your donations will enable us to cover our daily expenses for rent, miscellaneous charges, purchase of equipment, and above all to launch our future aviation projects described on this site. Make your transfer to the association's account using the details below.",
    SupportDonationBankTitle: "Bank details",
    SupportDonationName: "Association Meuh",
    SupportDonationBankAdress: "9 chemin de la Gravière",
    SupportDonationBankNPA: "CH-1225 Chêne-Bourg",
    SupportDonationBankCountry: "Switzerland",
    SupportDonationBankName: "Postfinance Account",
    SupportDonationBankTitleCcp: "CCP : ",
    SupportDonationBankCcp: "15-799990-3",
    SupportDonationBankTitleBic: "BIC : ",
    SupportDonationBankBic: "POFICHBEXXX",
    SupportDonationBankTitleIban: "IBAN : ",
    SupportDonationBankIban: "CH19 0900 0000 1579 9990 3",
    SupportDonationBankDesc:
      "As our association is recognized as a public utility by the Canton of Geneva, your donations are tax-deductible. At the beginning of the year, you will receive a certificate confirming that your donation was made during the previous tax period.",
    SupportVolunteer: "Become a volunteer",
    SupportVolunteerDesc:
      "If you'd like to support the association's actions and projects by sharing your experience and skills, become a volunteer who is willing to make commitments and, above all, keep them. Don't wait any longer, contact us at : ",
    SupportPartnership: "Partnership",
    SupportPartnershipDesc:
      "Interested in partnering with the association? Don't wait any longer, contact us via our",
    SupportPartnershipCTA: "contact form.",
    SupportThanks: "Thank you for your support !",
    SupportThanksDesc:
      "If you would like more information about our activities and funding requirements, please use our",
    SupportThanksCTA: "contact form.",
    StatusTitle: "Bylaws & Annual Reports",
    StatusFile1: "Bylaws",
    AnnualReport2021: "Annual Report 2021",
    AnnualReport2022: "Annual Report 2022",
    AnnualReport2023: "Annual Report 2023",
    GalleryTitle: "Gallery",
  },
  fr: {
    titles: {
      home: "Accueil",
      association: "Association",
      gallery: "Galerie",
      support: "Nous soutenir",
      workshop: "Atelier",
      projects: "Projets",
      contact: "Contact",
      donation: "Donation",
      status: "Status",
    },
    input: {
      LabelName: "Prénom",
      LabelSurname: "Nom",
      LabelEmail: "Email",
      LabelMessage: "Message",
      LabelTel: "Téléphone",
      SubmitCTA: "Envoyer",
      Name: "Entrez votre prénom...",
      Surname: "Entrez votre nom...",
      Email: "Entrez votre email...",
      Message: "Entrez votre message...",
      Tel: "Entrez votre numéro de téléphone...",
    },
    NavbarAssociation: "Association",
    NavbarWorkshop: "Atelier",
    NavbarProjects: "Projets",
    NavbarGallery: "Galerie",
    NavbarContact: "Nous contacter",
    NavbarSupport: "Nous soutenir",
    FooterTitle: "Sur le site",
    FooterAssociation: "Association",
    FooterWorkshop: "Atelier",
    FooterProjects: "Projets",
    FooterGallery: "Galerie",
    FooterContact: "Nous contacter",
    FooterSupport: "Nous soutenir",
    FooterStatus: "Statuts & Rapports Annuels",
    FooterRights: "© Association Meuh 2024 - Tous droits réservés",
    FooterAmasix: "Site crée par",
    FooterAmasixLink: " l'Amasix",
    HomeTitle: "Association Meuh",
    HomeDesc:
      "L’Association Meuh est une association à but non lucratif, reconnue d’utilité publique. Elle favorise l’intégration et la réinsertion de personnes en difficulté. Installée dans la région des trois Chêne (Genève) depuis 2008, elle propose de nombreuses activités techniques et artistiques réalisées dans un espace de travail socio-éducatif collectif avec l’aide de bénévoles.",
    AssociationTitle: "Association",
    AssociationImageDesc:
      "Un partage collectif des talents pour le bénéfice de chacun",
    AssociationRole: "Notre rôle",
    AssociationRoleDesc:
      "Notre équipe s’est constituée en association à but non lucratif en 2021. Elle bénéficie du statut d’utilité publique depuis 2023. Installés à Chêne-Bourg (Genève) depuis 2008, nous favorisons l’intégration et la réinsertion de personnes en difficulté grâce à de nombreuses activités techniques et artistiques réalisées dans un espace de travail socio-éducatif collectif, accompagnées par des bénévoles et des spécialistes.",
    AssociationBenefits: "Nos bénéficiaires",
    AssociationBenefitsDesc:
      "Nous accueillons tous types de personnes aux parcours et horizons différents : jeunes en décrochage scolaire, seniors en recherche d’activité et socialisation, adultes en réinsertion ou à l’assurance invalidité, migrants cherchant à s’intégrer par la pratique de langue française, demandeurs d’emploi ou en fin de droit, nouveaux résidents non francophones à Genève, etc...",
    AssociationObjectives: "Nos objectifs",
    AssociationObjectives_1:
      "Être un acteur reconnu de la cohésion sociale genevoise",
    AssociationObjectives_2:
      "Stimuler l’apprentissage et le savoir-faire par la production d’objets techniques complexes",
    AssociationObjectives_3:
      "Favoriser la responsabilisation, la rigueur, la confiance en soi et l’autonomie individuelle",
    AssociationObjectives_4:
      "Partager les valeurs d’esprit citoyen, d’écoute, de solidarité et de respect",
    AssociationObjectives_5: "Offrir une stimulation créative et encadrée",
    AssociationObjectives_6:
      "Mettre à disposition un espace d’échanges multiculturel et multi-activités",
    AssociationObjectives_7:
      "Mettre en valeur les talents artistiques de chacun afin d'aider nos bénéficiaires à reprendre pied dans la vie active après une période difficile",
    AssociationObjectives_8:
      "Aider les personnes à se relancer dans la vie active et à valoriser leurs connaissances et savoir-faire",
    AssociationObjectives_9:
      "Appuyer par des conseils et un suivi la création de micro-entreprises individuelles",
    AssociationTeam: "Notre équipe",
    AssociationTeamXavier: "Xavier Schmidt",
    AssociationTeamXavierDesc: "Responsable d’atelier",
    AssociationTeamMarius: "Marius Zainea",
    AssociationTeamMariusDesc: "Comptable",
    AssociationTeamVithursan: "Vithursan Sivapalan",
    AssociationTeamVithursanDesc: "Opérateur de découpeuse laser",
    AssociationTeamIlyas: "Ilyas Siouda",
    AssociationTeamIlyasDesc: "Ingénieur CAD/CAM",
    AssociationTeamLoic: "Loïc Emmert",
    AssociationTeamLoicDesc: "Ingénieur aéronautique",
    AssociationHistory: "Notre histoire",
    AssociationHistoryDesc:
      "Notre atelier voit le jour en 2008. Après avoir changé plusieurs fois de nom et de statut, il devient l’Atelier Meuh et prend la forme d’un espace autogéré à but non-lucratif, mis à disposition d’artistes et d’entrepreneurs sous certaines conditions. Puis, nous nous sommes constitués en association, le 25 juin 2021.",
    WorkshopTitle: "Atelier",
    WorkshopDesc:
      "Un espace de création et d’apprentissage en aéromodélisme, composites et arts plastiques à disposition de tous",
    WorkshopExpertise: "Notre savoir-faire",
    WorkshopCutting: "Découpe",
    WorkshopCuttingDesc:
      "Création et découpe de pièces sur machines dédiées…un gain de temps précieux lors du processus de fabrication ! Une activité nécessitant préparation, concentration, patience et créativité.",
    WorkshopMolding: "Moulage",
    WorkshopMoldingDesc:
      "Travaux de moulages en matériaux composites, résines et matériaux divers : carbone, Kevlar, fibres de verre, etc.... Une activité qui peut être incluse dans des cours personnalisés.",
    WorkshopClassicWork: "Travaux classiques",
    WorkshopClassicWorkDesc:
      "Savoir-faire classique ou spécialisé avec divers outils (tour à bois, ponceuses, etc.) et matériaux (bois légers, mousses, béton, plâtre, peintures, matériaux recyclés, balsa, contreplaqué, fibres de verre, etc.). Construction d’objets sur mesure et restauration de vieux meubles.",
    WorkshopEquipment: "Notre équipement",
    WorkshopEquipmentDesc:
      "L’atelier est principalement doté d’outils traditionnels. Nos machines sont des modèles de loisir à vocation artistique.",
    WorkshopEquipment_1:
      "Ancienne table à dessin d’architecte avec règles et équerres",
    WorkshopEquipment_2: "Découpeuse à fil chaud pour polystyrène",
    WorkshopEquipment_3: "Découpeuse laser CNC",
    WorkshopEquipment_4: "Fraiseuse CNC",
    WorkshopCourse: "Nos cours",
    WorkshopCourseTargetAudience: "Public-cible",
    WorkshopCourseTargetAudience_1:
      "Jeunes étudiant(e)s des écoles d’art et de design désirant apprendre ou tester des techniques particulières",
    WorkshopCourseTargetAudience_2:
      "Jeunes (dès 15 ans) en réinsertion socio-éducative ou socio-professionnelle",
    WorkshopCourseTargetAudience_3:
      "Adultes en période de recherche d’emploi et désirant acquérir d’autres connaissances",
    WorkshopCourseMain:
      "Activité principale enseignée : l’aéromodélisme artistique",
    WorkshopCourseMainDesc:
      "A partir de croquis, d’un plan ou d’un kit, construire et faire voler un engin volant radiocommandé. Activité créative, spectaculaire et originale, elle demande technique, soin, patience et précision : Vache ou hippopotame volants, oiseau préhistorique, arche de Noé, drone à carrosserie de frelon, araignée géante, etc… tout est possible !",
    WorkshopPrice: "Tarifs et conditions : ",
    WorkshopPriceCTA: "Contactez-nous !",
    ProjectTitle: "Projets",
    ProjectCow: "Projet N°2 - La vache volante",
    ProjectCowDesc:
      "Projet de conception et construction d’un véritable petit avion (pas un modèle réduit) équipé d’un moteur électrique, au fuselage en forme de vache.",
    ProjectCowObjectives: "Objectifs",
    ProjectCowObjectives_1:
      "Concevoir et construire collectivement, sous la supervision de l'office fédéral de l'aviation civile (OFAC) et en collaboration avec l'Experimental Aviation of Switzerland (EAS), un petit avion léger monoplace (ou biplace) au fuselage en forme de vache, équipé d'un moteur électrique non polluant.",
    ProjectCowObjectives_2:
      "Rassembler les membres de l’association autour d’un projet original et unique en son genre pour partager les valeurs de l’intégration et de la diversité en faisant la promotion de la Suisse via le symbole de la vache.",
    ProjectCowConstruction: "Etapes de construction",
    ProjectCowConstructionDesc:
      "La première étape du projet consiste à mandater un ingénieur aéronautique afin d’effectuer une indispensable étude de faisabilité technique, législative et financière. Coût estimé : 30'000.- francs. Une fois cette étape franchie, nous établirons les plans de fabrication du prototype puis organiserons sa fabrication en partenariat avec plusieurs spécialistes et organismes de la région.",
    ProjectCowFeasibility: "Etude de faisabilité",
    ProjectCowDetails: "Détails (provisoires) du prototype d’avion imaginé",
    ProjectCowDetails_1: "Type d’avion :",
    ProjectCowDetails_1_1:
      " expérimental, certifié en Suisse par l’OFAC/EAS, monoplace ou biplace",
    ProjectCowDetails_2: "Fuselage :",
    ProjectCowDetails_2_2:
      " peau en fibre de verre et carbone, structure tubulaire en acier soudé, composites ou bois.",
    ProjectCowDetails_3: "Forme :",
    ProjectCowDetails_3_3:
      " vache Holstein (blanche, tachetée de noir ou brun)",
    ProjectCowDetails_4: "Motorisation :",
    ProjectCowDetails_4_4: " kit Siemens ou Pipistrel, électrique (35 à 50 cv)",
    ProjectCowDetails_5: "Envergure :",
    ProjectCowDetails_5_5: " environ 9 mètres - Longueur : environ 6m50",
    ProjectCowDetails_6: "Poids :",
    ProjectCowDetails_6_6: " selon réglementation en vigueur",
    ProjectCowDetails_7: "Vitesse de vol :",
    ProjectCowDetails_7_7: " 90 Km/h environ",
    ProjectCowDetails_8: "Equipement annexe :",
    ProjectCowDetails_8_8:
      " fumigènes et système sonore (diffusion de mugissements ou bruits de cloches)",
    ProjectCowCTA: "Participez à ce projet !",
    ProjectPlane: "Projet N°1 - Construction d’un avion léger en kit",
    ProjectPlaneObjectives: "Objectifs",
    ProjectPlaneObjectives_1:
      "Construire collectivement un petit avion de tourisme en kit en milieu socio-éducatif dans la région d’Arve & Lac et des 3 Chêne",
    ProjectPlaneObjectives_2:
      "Soutenir l’intégration de personnes en difficulté et la réinsertion professionnelle de personnes diverses",
    ProjectPlaneObjectives_3:
      "Accueillir et encadrer les participants grâce au concours de professionnels et de bénévoles spécialisés",
    ProjectPlaneObjectives_4:
      "Initier les intéressés sur simulateur de vol et plus tard à l’apprentissage du pilotage dans un cadre sécurisé",
    ProjectPlaneObjectives_5:
      "Proposer des baptêmes de l’air sur notre avion une fois terminé",
    ProjectPlaneBudget: "Nos besoins pour ce projet",
    ProjectPlaneBudget_1:
      "Un atelier de construction d’environ 150 m2 avec porte de garage pour faire entrer et sortir l’avion (dont les ailes sont pliantes)",
    ProjectPlaneBudget_2: "Au minimum 2 à 3 assistants à 80 / 100 %",
    ProjectPlaneBudget_3:
      "Un peu d’équipement, d’ameublement et d’outillage de construction",
    ProjectPlaneBudget_4: "Un kit d’avion avec son moteur et instruments",
    ProjectPlaneBudget_5:
      "Une aide pour les frais d’exploitation annuels de l’atelier",
    ProjectPlaneCTA: "Participez à ce projet !",
    ContactTitle: "Nous contacter",
    ContactDesc_1: "Vous souhaitez nous faire part d’une remarque ?",
    ContactDesc_2: "Merci de le faire à travers le formulaire d’en dessous.",
    ContactInputTitleFirstName: "Prénom",
    ContactInputFirstName: "Entrez votre prénom",
    ContactInputTitleName: "Nom",
    ContactInputName: "Entrez votre nom",
    ContactInputTitleEmail: "Email",
    ContactInputEmail: "Entrez votre email",
    ContactInputTitlePhone: "Téléphone",
    ContactInputPhone: "(+41) 77 777 77 77",
    ContactInputTitleMessage: "Message",
    ContactInputMessage: "Laissez un message complémentaire",
    ContactInputCTA: "Envoyer",
    SupportTitle: "Nous soutenir",
    SupportDescription_1:
      "Vous souhaitez nous soutenir dans nos actions sociales ?",
    SupportDescription_2: " Faites un don ou aidez-nous avec un partenariat.",
    SupportDonation: "Faire un don",
    SupportDonationDescripton:
      "N'étant pas subventionnés, vos dons nous permettront d'assurer nos frais quotidiens de loyer, de charges diverses, d'achat de matériel, et surtout de lancer nos futurs projets d'aviation décrits sur ce site. Effectuez votre virement sur le compte de l’association grâce aux informations ci-dessous.",
    SupportDonationBankTitle: "Coordonnées bancaire",
    SupportDonationName: "Association Meuh",
    SupportDonationBankAdress: "9 chemin de la Gravière",
    SupportDonationBankNPA: "CH-1225 Chêne-Bourg",
    SupportDonationBankCountry: "Suisse",
    SupportDonationBankName: "Compte Postfinance",
    SupportDonationBankTitleCcp: "CCP : ",
    SupportDonationBankCcp: "15-799990-3",
    SupportDonationBankTitleBic: "BIC : ",
    SupportDonationBankBic: "POFICHBEXXX",
    SupportDonationBankTitleIban: "IBAN : ",
    SupportDonationBankIban: "CH19 0900 0000 1579 9990 3",
    SupportDonationBankDesc:
      "Notre association étant reconnue d’utilité publique par le Canton de Genève, vos dons sont déductibles de votre revenu imposable. Vous recevrez en début d’année une attestation de don effectué durant la période fiscale précédente.",
    SupportVolunteer: "Devenir bénévole",
    SupportVolunteerDesc:
      "Vous souhaitez soutenir les actions et projets de l'association en faisant profiter de votre expérience et vos compétences en devenant un(e) bénévole décidé à prendre des engagements et surtout à les tenir. N’attendez plus contactez nous à l’adresse mail : ",
    SupportPartnership: "Partenariat",
    SupportPartnershipDesc:
      "Vous souhaitez effectuer un partenariat avec l’association ? N’attendez plus contactez nous à travers notre",
    SupportPartnershipCTA: "formulaire de contact.",
    SupportThanks: "Merci de votre soutien !",
    SupportThanksDesc:
      "Si vous souhaitez plus d’informations sur nos activités et nos besoins de financement, à travers notre ",
    SupportThanksCTA: "formulaire de contact.",
    StatusTitle: "Statuts & Rapports Annuels",
    StatusFile1: "Statuts",
    AnnualReport2021: "Rapport annuel 2021",
    AnnualReport2022: "Rapport annuel 2022",
    AnnualReport2023: "Rapport annuel 2023",
    GalleryTitle: "Galerie",
  },
};
