<template>
  <AppLayout>
    <div class="max-w-7xl md:px-20 px-5 py-5 mx-auto space-y-2 md:space-y-5">
      <h1 class="pb-4">{{ $t('WorkshopTitle') }}</h1>
      <img src="/assets/latelier-img.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image atelier">
      <span class="flex justify-center text-center font-semibold italic md:text-2xl md:px-12 lg:px-52">"{{ $t('WorkshopDesc') }}"</span>
      <section class="pt-2 md:pt-4 space-y-2 md:space-y-8ß">
        <div>
          <h2>{{ $t('WorkshopExpertise') }}</h2>
          <h3 class="mt-2 md:mt-4">{{ $t('WorkshopCutting') }}</h3>
          <div class="grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-5 mt-1 md:mt-2">
            <img src="/assets/latelier-decoupe_1.png" class="rounded-lg border-2 border-custom-blue w-full" alt="image découpe">
            <img src="/assets/latelier-decoupe_2.png" class="rounded-lg border-2 border-custom-blue w-full" alt="image découpe">
            <img src="/assets/latelier-decoupe_3.png" class="rounded-lg border-2 border-custom-blue w-full" alt="image découpe">
            <img src="/assets/latelier-cutting.jpg" class="rounded-lg border-2 border-custom-blue w-full" alt="image découpe">
          </div>
          <p class="mt-2 md:mt-4">{{ $t('WorkshopCuttingDesc') }}</p>
        </div>
        <div>
          <h3>{{ $t('WorkshopMolding') }}</h3>
          <div class="grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-5 mt-1 md:mt-2">
            <img src="/assets/latelier-moulage_1.png" class="rounded-lg border-2 border-custom-blue w-full" alt="image moulage">
            <img src="/assets/latelier-moulage_2.png" class="rounded-lg border-2 border-custom-blue w-full" alt="image moulage">
            <img src="/assets/latelier-moulage_3.png" class="rounded-lg border-2 border-custom-blue w-full" alt="image moulage">
            <img src="/assets/latelier-moulage_4.png" class="rounded-lg border-2 border-custom-blue w-full" alt="image moulage">
          </div>
          <p class="mt-2 md:mt-4">{{ $t('WorkshopMoldingDesc') }}</p>
        </div>
        <div>
          <h3>{{ $t('WorkshopClassicWork') }}</h3>
          <p class="mt-1 md:mt-2">{{ $t('WorkshopClassicWorkDesc') }}</p>
        </div>
      </section>
      <section class="pt-4 md:pt-8">
        <h2>{{ $t('WorkshopEquipment') }}</h2>
        <p class="mt-1 md:mt-2">{{ $t('WorkshopEquipmentDesc') }}</p>
        <ul class="space-y-3 md:space-y-5 mt-2 md:mt-4">
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('WorkshopEquipment_1') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('WorkshopEquipment_2') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('WorkshopEquipment_3') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('WorkshopEquipment_4') }}</p>
            </li>
          </ul>
      </section>
      <section class="pt-4 md:pt-8">
        <h2>{{ $t('WorkshopCourse') }}</h2>
        <h3 class="mt-2 md:mt-4">{{ $t('WorkshopCourseTargetAudience') }}</h3>
        <ul class="space-y-3 md:space-y-5 pt-1 md:pt-2">
            <li class="flex items-center space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm"></div>
              <p class="w-11/12 float-right">{{ $t('WorkshopCourseTargetAudience_1') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('WorkshopCourseTargetAudience_2') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('WorkshopCourseTargetAudience_3') }}</p>
            </li>
          </ul>
          <h3 class="leading-5 mt-4 md:mt-8">{{ $t('WorkshopCourseMain') }}</h3>
          <p class="mt-1 md:mt-2">{{ $t('WorkshopCourseMainDesc') }}</p>
          <div class="font-semibold mt-4 md:mt-8 md:text-2xl">
            <span>{{ $t('WorkshopPrice') }}</span>
            <router-link to="/contact" class="text-custom-dark-blue hover:text-custom-blue underline">{{ $t('WorkshopPriceCTA') }}</router-link>
          </div>
      </section>
    </div>
  </AppLayout>
</template>
<script setup>
</script>