<template>
  <AppLayout>
    <div class="max-w-7xl px-5 md:px-20 py-5 mx-auto">
      <h1>{{ $t('SupportTitle') }}</h1>
      <p class="flex flex-col items-center text-center mt-2 md:mt-4 px-6 md:px-0">
        <span>{{ $t('SupportDescription_1') }}</span>
        <span>{{ $t('SupportDescription_2') }}</span>
      </p>
      <h2 class="mt-8 md:mt-16">{{ $t('SupportDonation') }}</h2>
      <p class="mt-1 md:mt-2">{{ $t('SupportDonationDescripton') }}</p>
      <h3 class="mt-1 md:mt-2">{{ $t('SupportDonationBankTitle') }}</h3>
      <div class="flex flex-col mt-1 md:mt-2">
        <span>{{ $t('SupportDonationName') }}</span>
        <span>{{ $t('SupportDonationBankAdress') }}</span>
        <span>{{ $t('SupportDonationBankNPA') }}</span>
        <span>{{ $t('SupportDonationBankCountry') }}</span>
      </div>
      <div>
        <span>{{ $t('SupportDonationBankName') }}</span>
        <div>
          <span class="font-semibold">{{ $t('SupportDonationBankTitleCcp') }}</span>
          <span>{{ $t('SupportDonationBankCcp') }}</span>
        </div>
        <div>
          <span class="font-semibold">{{ $t('SupportDonationBankTitleBic') }}</span>
          <span>{{ $t('SupportDonationBankBic') }}</span>
        </div>
        <div>
          <span class="font-semibold">{{ $t('SupportDonationBankTitleIban') }}</span>
          <span>{{ $t('SupportDonationBankIban') }}</span>
        </div>
      </div>
      <p class="mt-1 md:mt-2">{{ $t('SupportDonationBankDesc') }}</p>
      <h2 class="mt-4 md:mt-8">{{ $t('SupportVolunteer') }}</h2>
      <p class="mt-1 md:mt-2">{{ $t('SupportVolunteerDesc') }} <span class="font-semibold">association.meuh.geneve@gmail.com</span></p>
      <h2 class="mt-4 md:mt-8">{{ $t('SupportPartnership') }}</h2>
      <p class="mt-1 md:mt-2">{{ $t('SupportPartnershipDesc') }} <router-link to="/contact" class="md:text-xl text-custom-dark-blue underline font-semibold hover:text-custom-blue transition ease-in-out">{{ $t('SupportPartnershipCTA') }}</router-link></p>
      <h2 class="mt-4 md:mt-8">{{ $t('SupportThanks') }}</h2>
      <div class="mt-1 md:mt-2">
        <p>{{ $t('SupportThanksDesc') }} <router-link to="/contact" class="md:text-xl text-custom-dark-blue underline font-semibold hover:text-custom-blue transition ease-in-out">{{ $t('SupportThanksCTA') }}</router-link></p>

      </div>
    </div>
  </AppLayout>
</template>

<style scoped>
@media (max-width: 768px) {
  span {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  span {
    font-size: 18px;
  }
}
</style>