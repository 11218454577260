<template>
  <AppLayout>
    <div class="max-w-7xl px-5 md:px-20 py-5 mx-auto">
      <h1 class="pb-4 mb-4">{{ $t('ProjectTitle') }}</h1>
      <img src="/assets/lesprojets-img.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image projets">
      <section class="mt-8 md:mt-16">
        <h2>{{ $t('ProjectPlane') }}</h2>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-3 mt-2 md:mt-4">
          <img src="/assets/lesprojets-avion_1.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image avion kit">
          <img src="/assets/lesprojets-avion_2.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image avion kit">
          <img src="/assets/lesprojets-avion_3.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image avion kit">
          <img src="/assets/lesprojets-avion_4.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image avion kit">
        </div>
        <h3 class="pt-2 md:pt-4">{{ $t('ProjectPlaneObjectives') }}</h3>
        <ul class="space-y-3 md:space-y-5 pt-1 md:pt-4">
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneObjectives_1') }}</p>
          </li>
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneObjectives_2') }}</p>
          </li>
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneObjectives_3') }}</p>
          </li>
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneObjectives_4') }}</p>
          </li>
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneObjectives_5') }}</p>
          </li>
        </ul>
        <h3 class="pt-4 md:pt-8">{{ $t('ProjectPlaneBudget') }}</h3>
        <ul class="space-y-3 md:space-y-5 pt-1 md:pt-4">
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneBudget_1') }}</p>
          </li>
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneBudget_2') }}</p>
          </li>
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneBudget_3') }}</p>
          </li>
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneBudget_4') }}</p>
          </li>
          <li class="flex space-x-2 md:space-x-4">
            <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
            <p class="w-11/12 float-right">{{ $t('ProjectPlaneBudget_5') }}</p>
          </li>
        </ul>
        <div class="flex pt-4 md:pt-8">
          <router-link to="/support" class="text-custom-dark-blue hover:text-custom-blue underline text-xl md:text-2xl font-semibold">{{ $t('ProjectPlaneCTA') }}</router-link>
        </div>
      </section>
      <section class="pt-12 md:pt-24 space-y-2 md:space-y-4">
        <h2>{{ $t('ProjectCow') }}</h2>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-3">
          <img src="/assets/lesprojets-vache_1.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image vache volante">
          <img src="/assets/lesprojets-vache_2.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image vache volante">
          <img src="/assets/lesprojets-vache_3.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image vache volante">
          <img src="/assets/lesprojets-vache_4.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image vache volante">
        </div>
        <p>{{ $t('ProjectCowDesc') }}</p>
        <h3 class="pt-2 md:pt-4">{{ $t('ProjectCowObjectives') }}</h3>
        <ul class="space-y-3 md:space-y-5 pt-1 md:pt-2">
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('ProjectCowObjectives_1') }}</p>
            </li>
            <li class="flex space-x-2 md:space-x-4">
              <div class="w-3 h-3 md:w-4 md:h-4 bg-custom-dark-blue rounded-sm mt-1"></div>
              <p class="w-11/12 float-right">{{ $t('ProjectCowObjectives_2') }}</p>
            </li>
          </ul>
          <h3 class="pt-2 md:pt-4">{{ $t('ProjectCowConstruction') }}</h3>
          <p>{{ $t('ProjectCowConstructionDesc') }}</p>
          <h3 class="pt-2 md:pt-4">{{ $t('ProjectCowDetails') }}</h3>
          <ul class="flex flex-col space-y-2">
            <li>
              <span class="font-semibold">{{ $t('ProjectCowDetails_1') }}</span>
              <span>{{ $t('ProjectCowDetails_1_1') }}</span>
            </li>
            <li>
              <span class="font-semibold">{{ $t('ProjectCowDetails_2') }}</span>
              <span>{{ $t('ProjectCowDetails_2_2') }}</span>
            </li>
            <li>
              <span class="font-semibold">{{ $t('ProjectCowDetails_3') }}</span>
              <span>{{ $t('ProjectCowDetails_3_3') }}</span>
            </li>
            <li>
              <span class="font-semibold">{{ $t('ProjectCowDetails_4') }}</span>
              <span>{{ $t('ProjectCowDetails_4_4') }}</span>
            </li>
            <li>
              <span class="font-semibold">{{ $t('ProjectCowDetails_5') }}</span>
              <span>{{ $t('ProjectCowDetails_5_5') }}</span>
            </li>
            <li>
              <span class="font-semibold">{{ $t('ProjectCowDetails_6') }}</span>
              <span>{{ $t('ProjectCowDetails_6_6') }}</span>
            </li>
            <li>
              <span class="font-semibold">{{ $t('ProjectCowDetails_7') }}</span>
              <span>{{ $t('ProjectCowDetails_7_7') }}</span>
            </li>
            <li>
              <span class="font-semibold">{{ $t('ProjectCowDetails_8') }}</span>
              <span>{{ $t('ProjectCowDetails_8_8') }}</span>
            </li>
          </ul>
          <div class="flex pt-2 md:pt-4">
            <router-link to="/support" class="text-custom-dark-blue hover:text-custom-blue underline text-xl md:text-2xl font-semibold">{{ $t('ProjectCowCTA') }}</router-link>
          </div>
      </section>

    </div>
  </AppLayout>
</template>
<script setup>
</script>