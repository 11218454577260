<template>
    <AppLayout>
      <div class="max-w-7xl px-5 md:px-20 py-5 mx-auto flex flex-col space-y-5">
        <h1 class="pb-4">{{ $t('GalleryTitle') }}</h1>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-y-5 md:gap-x-10">
          <img 
            v-for="n in 48" 
            :key="n" 
            :src="'/assets/gallery/' + (n < 10 ? '0' + n : n) + '.png'" 
            class="rounded-lg w-full border-2 border-custom-blue cursor-pointer hover:scale-105 transition ease-in-out 1s"
            :alt="'image galerie ' + n" 
            @click="showModal(n)"
          >
        </div>
        <div v-if="currentImage" class="fixed top-[-20px] left-0 w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center"  @click="currentImage = null">
          <div class="relative" @click.stop>
            <img 
              :src="'/assets/gallery/' + (currentImage < 10 ? '0' + currentImage : currentImage) + '.png'" 
              class="rounded-lg w-[300px] md:w-[700px] h-auto border-2 border-custom-blue"
              :alt="'image galerie ' + currentImage"
            >
          </div>
        </div>
      </div>
    </AppLayout>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentImage: null,
      };
    },
    methods: {
      showModal(imgNumber) {
        this.currentImage = imgNumber;
      },
    },
  };
  </script>
  