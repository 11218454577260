<template>
  <AppLayout>
    <div class="max-w-7xl pr-5 md:px-20 px-5 py-5 mx-auto space-y-2 md:space-y-5">
      <h1 class="pb-4">Association Meuh</h1>
      <img src="/assets/index-img.png" class="rounded-lg w-full border-2 border-custom-blue" alt="image association meuh">
      <p>{{ $t('HomeDesc') }}</p>
    </div>
  </AppLayout>
</template>

<script>
</script>